@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Nanum+Gothic&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Josefin+Sans:wght@300;700&family=Nanum+Gothic&display=swap');

* { 
  margin: 0;
  font-family: 'Josefin Sans', sans-serif;
}

html {
  height: 100%;
}

body {
  height: 100%;
}

.App {
  padding: 50px;
  background-color:#202020;
  min-height: 100vh;
}

.main-container {
  background-color: #D4D2CE;
  padding: 60px;
}

.header {
  display: flex;
  justify-content: space-between;
}

h2 {
  font-weight: 300;
  font-size: 20px;
  color: #202020;

 
}

.stroke-text {
  color: #202020;
  -webkit-text-stroke: 1px #202020;
  -webkit-text-fill-color:transparent;
}

h1 {
  font-size: 80px;
  font-weight: 700;
  text-align: left;
  color: #202020;

  
}

h3 { 
  font-weight: 300;
  color: #202020;
}

h4 {
  font-weight: 300;
}

.top-part {
  display: flex;
  justify-content: space-between;
}

.bottom-part {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.name-block {
  /* display: flex; */
  /* align-items: flex-end; */
  /* flex-direction: column; */

  /* position: absolute; */
  /* bottom: 100px; */
  /* right: 90px; */
}

.bottom-contianer {
  padding-top: 60px;
}

.cover-image {
  height: 270px ;
}

@media only screen and (max-width: 900px) {
  
  .App {
    padding: 20px;
    background-color:#202020;
    height: fit-content;
  }

  .main-container {
    height: 95vh;
  }

  h1 {
    font-size: 70px;
    font-weight: 700;
    text-align: left;
    color: #202020;
  }

  h2 {
    font-size: 17px;
    font-weight: 300;
    color: #202020;
  }

  .cover-image {
    height: 180px ;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .bottom-contianer {
    margin-top: 30px;
    padding-top: 30px;
  }

  .main-container {
    padding: 20px;
  }

  .bottom-part {
    display:contents;
  }
  
  .name-block {
    align-items: flex-start;
    
  }
  
}

@media only screen and (max-width: 500px) {
  
  .App {
    padding: 15px;
    background-color:#202020;
    height: 100vh;
  }

  .main-container {
    height: 95vh;
  }

  h1 {
    font-size: 45px;
    font-weight: 700;
    text-align: left;
    color: #202020;
  }

  h2 {
    font-size: 13px;
    font-weight: 300;
    color: #202020;
  }

  .cover-image {
    height: 140px ;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .bottom-contianer {
    margin-top: 30px;
    padding-top: 30px;
  }

  .main-container {
    padding: 20px;
  }

  .name-block {
    align-items: flex-start;
    
  }
  
}